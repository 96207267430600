import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Toolbar from '@mui/material/Toolbar';
import React, {memo} from 'react';
import {useTranslation} from 'react-i18next';
import {Link as RouterLink, NavLink, useRouteMatch} from 'react-router-dom';

import {MY_PATIENTS_ROUTE} from '../../configs/routes';
import {NavigationProps} from '../../interfaces/navigation-props.interface';
import {APP_TITLE, LOGO_PATH, SUB_NAV_HEIGHT_DESKTOP} from '../../utils/constants';

function DesktopNavigation({open, items = []}: NavigationProps) {
  const {t} = useTranslation();

  const {path} = useRouteMatch();

  return (
    <Box hidden={!open}>
      <Toolbar
        disableGutters
        sx={{
          paddingLeft: 1,
          paddingRight: 1,
          zIndex: 9999,
          height: `${SUB_NAV_HEIGHT_DESKTOP}px`,
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            height: '100%',
          }}
        >
          <Grid
            item
            sx={{
              height: '100%',
            }}
          >
            <Link
              to={MY_PATIENTS_ROUTE.path || ''}
              component={RouterLink}
              display="flex"
              alignItems="center"
              height="100%"
            >
              <img height="40px" src={LOGO_PATH} alt={APP_TITLE} />
            </Link>
          </Grid>
          <Grid item>
            <List
              aria-label={'sub nav'}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                padding: 0,
              }}
            >
              {items.map(({to, label = '', href, target, counter}, index) => (
                <Badge
                  key={index}
                  sx={theme => ({
                    '& .MuiBadge-badge': {
                      top: theme.spacing(1.25),
                      right: theme.spacing(0.75),
                    },
                  })}
                  badgeContent={counter}
                  color="primary"
                >
                  {to ? (
                    <ListItemButton
                      to={to}
                      component={NavLink}
                      disableGutters
                      sx={theme => ({
                        ...(theme.components?.MuiButton?.styleOverrides?.root as Record<string, any>),
                        minHeight: '50px',
                        color: 'primary.main',
                        whiteSpace: 'nowrap',
                        borderBottom: '2px solid transparent',
                        paddingLeft: 0,
                        paddingRight: 0,
                        marginLeft: 1,
                        marginRight: 1,
                        textTransform: 'none'
                      })}
                      activeStyle={
                        path === to
                          ? {
                              borderBottom: '2px solid',
                            }
                          : {borderBottom: 'none'}
                      }
                    >
                      {t(label)}
                    </ListItemButton>
                  ) : (
                    <ListItemButton
                      href={href}
                      target={target}
                      component={Link}
                      disableGutters
                      sx={theme => ({
                        ...(theme.components?.MuiButton?.styleOverrides?.root as Record<string, any>),
                        minHeight: '50px',
                        color: 'primary.main',
                        whiteSpace: 'nowrap',
                        borderBottom: '2px solid transparent',
                        paddingLeft: 0,
                        paddingRight: 0,
                        marginLeft: 1,
                        marginRight: 1,
                        textTransform: 'none'
                      })}
                    >
                      {t(label)}
                    </ListItemButton>
                  )}
                </Badge>
              ))}
            </List>
          </Grid>
        </Grid>
      </Toolbar>
      <Divider />
    </Box>
  );
}

export const DesktopNavigationComponent = memo(DesktopNavigation);
